import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/svg/phone.svg";

import iconResponsive from "../../assets/image/svg/location.svg";
import results from "../../assets/image/svg/email.svg";
import pain from "../../assets/image/svg/opening.svg";

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const SectionStyled = styled(Section)`
 
  padding-bottom: 23px;
text-align:center;
background: #f7f7fb;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
   
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[2, 2, 2, 2]} pb={1}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="16px" mb={1}>
        {title}
      </Title>
      <Text fontSize="14px" variant="small">{children}</Text>
    </Box>
  </Box>
);

const Contactinfo = () => (
  <>
  <Separator />
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center"   data-aos="fade-up"
            data-aos-duration="300"
            data-aos-once="false"
            data-aos-delay="100">
       
          <Col lg="3" xs="6" className="mb-5 mb-lg-4 "
           >
            <FeatureCard title="Phone" iconImage={iconLayout}>
            <a href="tel:020 7099 6650"> 020 7099 6650 </a>
            </FeatureCard>
          </Col>
          
          <Col lg="3" xs="6" className="mb-5 mb-lg-4"   >
            <FeatureCard title="Email" iconImage={results}>
            <a href="mailto:info@dermamina.com">info@dermamina.com</a>
            </FeatureCard>
          </Col>
          
          <Col lg="3" xs="6" className="mb-5 mb-lg-4"  >
            <FeatureCard title="Address" iconImage={iconResponsive}>
           <a href="https://www.google.com/maps/place/114+New+Cavendish+St,+Fitzrovia,+London+W1W+6XT/@51.5200942,-0.1443808,17z/data=!3m1!4b1!4m5!3m4!1s0x48761ad5e3fc100d:0x78166a735cff5b65!8m2!3d51.5200909!4d-0.1421921"> 114 New Cavendish Street, London W1W 6XT</a>

            </FeatureCard>
          </Col>
          <Col lg="3" xs="6" className="mb-5 mb-lg-4" >
            <FeatureCard title="Opening Hours" iconImage={pain}>
           Mon - Sat : 9.30am - 8.30pm <br />
           Sun : 10am- 7pm

            </FeatureCard>
          </Col>
         
        </Row>

        
  
      
      </Container>
    </SectionStyled>
    <SeparatorEnd />
  </>
);

export default Contactinfo;
